import { jsx } from "react/jsx-runtime";
import { lazy, useMemo } from "react";
const LazyAccordion = lazy(
  () => import("./accordion-B73Cbbjp.js").then((module) => ({
    default: module.Accordion
  }))
);
function Accordion({
  id: _id,
  name: _name,
  ...props
}) {
  return /* @__PURE__ */ jsx(LazyAccordion, { ...props });
}
const useAccordionContent = () => {
  return useMemo(
    () => ({
      accordion: {
        component: Accordion
      }
    }),
    []
  );
};
export {
  LazyAccordion as Accordion,
  useAccordionContent
};
